define("kredits-web/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kredits: Ember.inject.service(),
    showDetailsPane: false,
    selectedContributorId: null,
    selectedContributionId: null,
    currentBlock: Ember.computed.alias('kredits.currentBlock'),
    contributions: Ember.computed.alias('kredits.contributions'),
    contributionsConfirmed: Ember.computed.alias('kredits.contributionsConfirmed'),
    contributionsUnconfirmed: Ember.computed.alias('kredits.contributionsUnconfirmed'),
    contributionsSorting: Object.freeze(['date:desc', 'time:desc', 'id:desc']),
    contributionsUnconfirmedSorted: Ember.computed.sort('contributionsUnconfirmed', 'contributionsSorting'),
    contributionsConfirmedSorted: Ember.computed.sort('contributionsConfirmed', 'contributionsSorting'),
    kreditsByContributor: Ember.computed.alias('kredits.kreditsByContributor'),
    kreditsToplistSorting: Ember.computed('showUnconfirmedKredits', function () {
      return this.showUnconfirmedKredits ? ['amountTotal:desc'] : ['amountConfirmed:desc'];
    }),
    kreditsToplist: Ember.computed('kreditsByContributor', function () {
      return this.kreditsByContributor.filter(function (c) {
        return c.amountTotal > 0;
      });
    }),
    kreditsToplistSorted: Ember.computed.sort('kreditsToplist', 'kreditsToplistSorting'),
    showUnconfirmedKredits: true,
    hideUnconfirmedKredits: Ember.computed.not('showUnconfirmedKredits'),
    showQuickFilterUnconfirmed: false,
    showQuickFilterConfirmed: false,
    showFullContributionSync: Ember.computed.gt('kredits.missingHistoricContributionsCount', 0),
    actions: {
      vetoContribution: function vetoContribution(contributionId) {
        this.kredits.veto(contributionId).then(function (transaction) {
          console.debug('[controllers:index] Veto submitted to chain: ' + transaction.hash);
        });
      },
      toggleQuickFilterUnconfirmed: function toggleQuickFilterUnconfirmed() {
        this.toggleProperty('showQuickFilterUnconfirmed');
      },
      toggleQuickFilterConfirmed: function toggleQuickFilterConfirmed() {
        this.toggleProperty('showQuickFilterConfirmed');
      }
    }
  });

  _exports.default = _default;
});
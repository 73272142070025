define("kredits-web/helpers/fmt-fiat-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fmtFiatCurrency(params) {
    var lang = navigator.language || navigator.userLanguage;
    var value = params[0];
    var currency = params[1] || 'EUR';
    if (currency === 'BTC') return "BTC ".concat(value);
    var formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code'
    });
    return formatter.format(params[0]);
  });

  _exports.default = _default;
});
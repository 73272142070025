define("kredits-web/utils/iso-date-is-today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(value) {
    var today = new Date().toISOString().split('T')[0];
    return value === today;
  }
});
define("kredits-web/helpers/fmt-amount-sats-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fmtAmountSatsTitle(params) {
    var amountSats = params[0];

    if (typeof amountSats === 'number' && amountSats > 0) {
      return "".concat(amountSats, " sats");
    } else {
      return '';
    }
  });

  _exports.default = _default;
});
define("kredits-web/utils/btc-conversions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.btcToSats = btcToSats;
  _exports.floatToBtc = floatToBtc;
  _exports.satsToBtc = satsToBtc;

  function floatToBtc(number) {
    return Number(number.toFixed(8));
  }

  function btcToSats(btc) {
    return Math.round(btc * 100000000);
  }

  function satsToBtc(sats) {
    return Math.round(sats / 100000000 * 100000000) / 100000000;
  }
});